import CameraAlt from '@mui/icons-material/CameraAlt';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  type EventDetailMimicData,
  EventDetailsMimic,
} from './EventDetailsMimic';
import {EventDetailsShimmer} from './shimmers/EventDetailsShimmer';
import {useEventDetails} from '../../hooks/useEventDetails';
import {type EventDetailsData} from '../../hooks/useEventFeedback';
import {formatLeadTime} from '../../utils/feedbackUtils';

export function EventDetailsWithHook({
  eventDetailsData,
}: {
  eventDetailsData: EventDetailsData;
}) {
  const {
    event,
    serial,
    speed,
    creationDate,
    recordedDate,
    org,
    orgGroupName,
    driver,
    leadTime,
    vehicleRegistration,
    eventTrigger,
  } = useEventDetails({eventDetailsData});

  const isLeadTimeExceedingThirtyMinutes =
    (leadTime?.minutes != null && leadTime?.minutes > 30) ||
    (leadTime?.hours ?? 0) > 0;

  return (
    <Stack borderRadius="16px" bgcolor="white" mt="18px" width="100%">
      <Stack
        width="100%"
        bgcolor="#EFEFEF"
        display="flex"
        direction="row"
        alignItems="center"
        columnGap="40px"
        p="8px 20px"
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <CameraAlt />
        <Typography fontSize="16px" fontWeight={600}>
          EVENT DETAILS
        </Typography>

        <Typography
          fontSize="16px"
          fontWeight={600}
          ml="auto"
          color={isLeadTimeExceedingThirtyMinutes ? 'error' : 'inherit'}
        >
          {formatLeadTime(leadTime)}
        </Typography>
      </Stack>
      <div
        style={{
          display: 'flex',
          padding: '16px 40px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Organisation: {org}
          </Typography>
          <Typography fontSize="12px">Group: {orgGroupName}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Event Trigger: {eventTrigger}
          </Typography>
          <Typography fontSize="12px">Event: {event}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Speed: {speed}
          </Typography>
          <Typography fontSize="12px">
            Vehicle: {vehicleRegistration}
          </Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Serial: {serial}
          </Typography>
          <Typography fontSize="12px">Driver: {driver}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Created: {creationDate}
          </Typography>
          <Typography fontSize="12px">Recorded: {recordedDate}</Typography>
        </div>
      </div>
    </Stack>
  );
}

export function EventDetails({
  eventDetailsData,
  mimicObject,
}: {
  eventDetailsData?: EventDetailsData;
  mimicObject?: EventDetailMimicData;
}) {
  if (eventDetailsData == null && mimicObject == null) {
    return <EventDetailsShimmer />;
  }

  if (eventDetailsData != null) {
    return <EventDetailsWithHook eventDetailsData={eventDetailsData} />;
  }

  if (mimicObject != null) {
    return <EventDetailsMimic mimicObject={mimicObject} />;
  }
}
