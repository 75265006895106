import {type RefObject, useCallback, useMemo} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {type FormikProps} from 'formik';

import {useFormValues} from './useFormValues';
import {routes} from '../../../routes';
import {useNavigationStore} from '../../common/stores/navigationStore';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {checkBehaviorTypes, processLookupObject} from '../utils/feedbackUtils';

interface EventReviewBottomBarHook {
  handleLateClip: () => void;
  navigationDrawerOpen: boolean;
  navigateBackToVideo: () => void;
  handleNavigateBack: () => void;
  searchParams: URLSearchParams;
  handleNoIssue: () => void;
  handleSessionPause: () => Promise<void>;
  noIssueDisabled: boolean;
  nextEventAvailable: boolean;
  feedbackFormRef: RefObject<FormikProps<Record<string, boolean>>>;
  handleFaultyClip: () => void;
  faultyClipSelected: boolean;
  noIssueSelected: boolean;
  lateClipSelected: boolean;
  nextDisabled: boolean;
}

export const useEventReviewBottomBar = (): EventReviewBottomBarHook => {
  const {
    formRef,
    webSocket,
    setShowOnBreakModal,
    resetContext,
    behaviours,
    handleLateClip,
    handleFaultyClip,
    handleNoIssue,
    notificationComments,
  } = useEventReviewContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formValues = useFormValues(formRef);
  const navigationDrawerOpen = useNavigationStore(
    (state) => state.navigationDrawerOpen,
  );

  const nextEventAvailable = useMemo(
    () => webSocket.events.length > 1,
    [webSocket.events.length],
  );

  const emptyForm = useMemo(
    () => !Object.values(formValues).some(Boolean),
    [formValues],
  );

  const cleanedKeys = useMemo(
    () => processLookupObject(formValues),
    [formValues],
  );
  const containsOnlyObservations = useMemo(
    () => checkBehaviorTypes(cleanedKeys, behaviours),
    [cleanedKeys, behaviours],
  );

  const noIssueSelected = useMemo(
    () => formValues['__lookupKey: n__No Issue'],
    [formValues],
  );

  const noIssueDisabled = useMemo(
    () => !emptyForm && !containsOnlyObservations && !noIssueSelected,
    [emptyForm, containsOnlyObservations, noIssueSelected],
  );

  const nextDisabled = useMemo(
    () =>
      emptyForm ||
      (containsOnlyObservations && !formValues['__lookupKey: n__No Issue']),
    [emptyForm, containsOnlyObservations, formValues],
  );

  const faultyClipSelected = useMemo(
    () => formValues['__lookupKey: fc__Faulty Clip'],
    [formValues],
  );

  const lateClipSelected = useMemo(
    () =>
      notificationComments.length > 0 &&
      notificationComments[0].text.includes('Late Clip'),
    [notificationComments],
  );

  const navigateBackToVideo = useCallback(() => {
    navigate(routes.eventReview.review);
  }, [navigate]);

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSessionPause = useCallback(async () => {
    try {
      setShowOnBreakModal(true);
      await formRef.current?.submitForm();
      navigate(routes.eventReview.base);
      webSocket.disconnect();
      resetContext();
    } catch (error) {
      toast('Error pausing session', {type: 'error'});
    }
  }, [navigate, webSocket, setShowOnBreakModal, resetContext, formRef]);

  return {
    handleLateClip,
    navigationDrawerOpen,
    navigateBackToVideo,
    handleNavigateBack,
    searchParams,
    handleNoIssue,
    handleSessionPause,
    noIssueDisabled,
    nextEventAvailable,
    feedbackFormRef: formRef,
    handleFaultyClip,
    faultyClipSelected,
    noIssueSelected,
    lateClipSelected,
    nextDisabled,
  };
};
