import {useState, useEffect, useCallback, useRef} from 'react';
import {toast} from 'react-toastify';

import {type EarlyWarningEventDataPost} from '@onroadvantage/onroadvantage-api';

import {useAuthStore} from '../../common/stores/authStore';
import {
  type Send,
  type WebSocketEvents,
  type WebSocketEventsTop,
} from '../WebsocketTypes';
import {useRedirectToBaseIfNotConnected} from './useRedirectToBaseIfNotConnected';
import {earlyWarningEventApi} from '../../../api';
import {config} from '../../../config';

export const useEventReviewWebSocket = () => {
  const [connected, setConnected] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [events, setEvents] = useState<WebSocketEvents[]>([]);
  const [queueEmpty, setQueueEmpty] = useState<boolean>(false);
  const wsRef = useRef<WebSocket | null>(null);
  const ssoToken = useAuthStore((state) => state.ssoToken);

  // Listen to connection to see if we need to redirect the user back to the base route
  useRedirectToBaseIfNotConnected({connected, connecting});

  const submitEventToVantage = useCallback(
    async ({event}: {event: EarlyWarningEventDataPost}) => {
      try {
        await earlyWarningEventApi.apiEarlyWarningEventPost({
          body: event,
        });
      } catch {
        toast('Error submitting event to vantage', {type: 'error'});
      }
    },
    [],
  );

  const isEventData = (data: unknown): data is WebSocketEventsTop => {
    return data != null && typeof data === 'object' && 'type' in data;
  };

  const connect = useCallback(() => {
    setConnecting(true);
    wsRef.current = new WebSocket(config.earlyWarningWebSocketUrl);

    wsRef.current.onopen = () => {
      wsRef.current?.send(JSON.stringify({type: 'auth', token: ssoToken}));
      setConnected(true);
      setConnecting(false);
    };

    wsRef.current.onmessage = (event: MessageEvent<string>) => {
      const data: unknown = JSON.parse(event.data);
      if (isEventData(data)) {
        switch (data.type) {
          case 'event':
            setEvents((prevEvents) => {
              if (prevEvents.length === 0) {
                data.data.reviewStartTime = new Date();
              }
              return [...prevEvents, data.data];
            });
            if (queueEmpty) {
              setQueueEmpty(false);
            }
            break;
          case 'reviewed_event':
            void submitEventToVantage({event: data.data.data});
            break;
          case 'no_more_events':
            if (events.length === 0) {
              setTimeout(() => {
                setQueueEmpty(true);
                wsRef.current?.send(JSON.stringify({type: 'event'}));
              }, 5000);
            }

            break;
          case 'error':
            toast(`Error from server: ${data.message}`, {type: 'error'});
            break;
          default:
            toast(`Unknown data type: ${data.message}`, {type: 'error'});
            break;
        }
      }
    };

    wsRef.current.onclose = () => {
      setConnected(false);
    };
  }, [ssoToken, queueEmpty, submitEventToVantage, events.length]);

  const disconnect = useCallback(() => {
    if (wsRef.current != null) {
      wsRef.current.close();
    }
  }, []);

  const sendReview = useCallback(
    (message: Send) => {
      if (wsRef.current == null || events.length === 0) {
        return;
      }

      wsRef.current.send(JSON.stringify(message));

      setEvents((prevEvents) => {
        if (prevEvents.length === 0) {
          return [];
        }
        const pendingEvents = prevEvents.slice(1);
        if (pendingEvents?.length > 0) {
          pendingEvents[0].reviewStartTime = new Date();
          return pendingEvents;
        }
        return prevEvents;
      });
    },
    [events],
  );

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  return {
    connected,
    events,
    sendReview,
    connect,
    disconnect,
    queueEmpty,
  };
};
