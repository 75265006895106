import {
  createContext,
  useContext,
  type ReactNode,
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {type Behaviour} from '@onroadvantage/onroadvantage-api';
import {type FormikProps} from 'formik';

import {
  type EventReviewContextType,
  useEventReviewContextResponseInitial,
} from './contextInitialValues';
import {behaviourApi} from '../../../api';
import {routes} from '../../../routes';
import {EventReviewBottomBar} from '../components/EventReviewBottomBar';
import {useGenerateLookupTables} from '../hooks/useGenerateLookupTables';
import {useEventReviewWebSocket} from '../hooks/useReviewEventWebSocket';

const EventReviewContext = createContext<EventReviewContextType>(
  useEventReviewContextResponseInitial,
);

interface EventReviewProviderProps {
  children: ReactNode;
}

export function EventReviewProvider({children}: EventReviewProviderProps) {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [showOnBreakModal, setShowOnBreakModal] = useState<boolean>(false);
  const [snapshotList, setSnapshotList] = useState<number[]>([]);
  const formRef = useRef<FormikProps<Record<string, boolean>>>(null);
  const [keyBuffer, setKeyBuffer] = useState<string>('');
  const [onEventSelectedShortCuts, setOnEventSelectedShortcuts] = useState<
    string[]
  >([]);
  const [behaviours, setBehaviours] = useState<Behaviour[]>();
  const [notificationComments, setNotificationComments] = useState<
    Array<{text: string; commentTime: Date}>
  >([]);
  const webSocket = useEventReviewWebSocket();
  const lookupTableData = useGenerateLookupTables({behaviours});

  const renderTopbar = useMemo(
    () => routes.eventReview.feedback === pathname,
    [pathname],
  );

  const memoizedWebSocketHookData = useMemo(
    () => ({
      webSocket,
    }),
    [webSocket],
  );

  const memoizedLookupTableData = useMemo(
    () => ({
      lookupTableData,
    }),
    [lookupTableData],
  );

  const resetContext = useCallback(() => {
    setSnapshotList([]);
    setOnEventSelectedShortcuts([]);
    formRef.current?.resetForm();
    setNotificationComments([]);
  }, []);

  const handleFaultyClip = useCallback(async () => {
    const currentValues = {...formRef?.current?.values};
    const key = '__lookupKey: fc__Faulty Clip';

    navigate(routes.eventReview.feedback);

    await formRef?.current?.setValues({
      ...currentValues,
      [key]: !currentValues[key],
    });
  }, [navigate]);

  const handleNoIssue = useCallback(async () => {
    const currentValues = {...formRef?.current?.values};
    const key = '__lookupKey: n__No Issue';

    await formRef?.current?.setValues({
      ...currentValues,
      [key]: !currentValues[key],
    });
  }, []);

  const handleLateClip = useCallback(() => {
    const commentContainingLateClip = notificationComments.find(
      (comment) => comment.text === 'Could not notify, Late Clip',
    );
    if (commentContainingLateClip != null) {
      setNotificationComments((prev) =>
        prev.filter(
          (prevComment) => prevComment.text !== 'Could not notify, Late Clip',
        ),
      );
    } else {
      setNotificationComments((prev) => [
        {text: 'Could not notify, Late Clip', commentTime: new Date()},
        ...prev,
      ]);
    }
  }, [notificationComments]);

  useEffect(() => {
    const fetchBehaviours = async () => {
      try {
        const {items} = await behaviourApi.apiBehaviourListingGet({});
        if (items != null) {
          setBehaviours(items);
        }
      } catch (err) {}
    };
    void fetchBehaviours();
  }, []);

  const contextValue: EventReviewContextType = {
    webSocket: memoizedWebSocketHookData.webSocket,
    formRef,
    keyBuffer,
    setKeyBuffer,
    snapshotList,
    setSnapshotList,
    onEventSelectedShortCuts,
    setOnEventSelectedShortcuts,
    showOnBreakModal,
    setShowOnBreakModal,
    resetContext,
    handleNoIssue,
    behaviours,
    notificationComments,
    setNotificationComments,
    handleFaultyClip,
    handleLateClip,
    lookupTableData: memoizedLookupTableData.lookupTableData,
  };

  return (
    <EventReviewContext.Provider value={contextValue}>
      {children}
      {renderTopbar && <EventReviewBottomBar />}
    </EventReviewContext.Provider>
  );
}

export const useEventReviewContext = (): EventReviewContextType =>
  useContext(EventReviewContext);
