import {useState} from 'react';

import type {EarlyWarningEventComment} from '@onroadvantage/onroadvantage-api';

import {useEventReviewContext} from '../contexts/EventReviewContext';

export interface DeletableComment {
  [key: symbol]: number;
  text: string;
  commentTime: Date;
}
export const useNotificationContacts = ({
  eventDetailsComments,
}: {
  eventDetailsComments?: EarlyWarningEventComment[];
}) => {
  const {setNotificationComments, notificationComments} =
    useEventReviewContext();
  const [comment, setComment] = useState<string>('');

  const handleCallTriggered = (contactName: string) => {
    setNotificationComments([
      ...notificationComments,
      {
        text: `Call triggered for ${contactName}`,
        commentTime: new Date(),
        [Symbol('iterator')]: 1,
      },
    ]);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() !== '') {
      setNotificationComments([
        ...notificationComments,
        {
          text: comment,
          commentTime: new Date(),
          [Symbol('iterator')]: notificationComments.length + 1,
        },
      ]);
      setComment('');
    }
  };

  const handleCommentDelete = (selectedComment: DeletableComment) => {
    const symbolKey = Object.getOwnPropertySymbols(selectedComment)[0];
    const commentSymbolValue = selectedComment[symbolKey];

    setNotificationComments((prev) =>
      prev.filter((c) => {
        const currentSymbolKey = Object.getOwnPropertySymbols(c)[0];
        return c[currentSymbolKey] !== commentSymbolValue;
      }),
    );
  };

  const commentsList = eventDetailsComments ?? notificationComments;

  return {
    comment,
    setComment,
    handleCallTriggered,
    handleCommentSubmit,
    commentsList,
    handleCommentDelete,
  };
};
